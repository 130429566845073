// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container h1 {
  color: #333; 
  margin-bottom: 20px; 
}

.container .date-filter label {
  display: inline-block; 
  margin:10px  10px 10px 10px; 
  font-size: 12px;
  font-weight: bold;
  
}

.container .date-filter input {
  padding: 5px;
  margin-bottom: 10px; 
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 10px;
}

.container button {
  padding: 10px 20px;
  margin-left: 30px; 
  background-color: #007bff; 
  color: #fff; 
  border: none; 
  border-radius: 5px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
}

.container button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,eAAe;EACf,iBAAiB;;AAEnB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container h1 {\n  color: #333; \n  margin-bottom: 20px; \n}\n\n.container .date-filter label {\n  display: inline-block; \n  margin:10px  10px 10px 10px; \n  font-size: 12px;\n  font-weight: bold;\n  \n}\n\n.container .date-filter input {\n  padding: 5px;\n  margin-bottom: 10px; \n  border: 1px solid #ccc;\n  border-radius: 10px;\n  font-size: 10px;\n}\n\n.container button {\n  padding: 10px 20px;\n  margin-left: 30px; \n  background-color: #007bff; \n  color: #fff; \n  border: none; \n  border-radius: 5px; \n  cursor: pointer; \n  transition: background-color 0.3s ease; \n}\n\n.container button:hover {\n  background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
