import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import AxiosInstance from '../core/axiosInstance';
import './SummaryScreen.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const SummaryScreen = ( formData ) => {
  const [data, setData] = useState({});
  const location = useLocation();

  let dados = location.state.Object;
  let dadosDependentes = location.state.dependentes;

  console.log('Recuperando dados', dados);
  console.log('Recuperando dados de dependentes', dadosDependentes);

  useEffect( async () => {
    console.log(localStorage.getItem("accessToken"))
    const data = await AxiosInstance.get("http://127.0.0.1:5000/form/get", {
      headers: {
        Authorization: `Basic ${localStorage.getItem("accessToken")}`,
      },
    })
    
    setData(data.data[data.data.length -1])
  },[])

  console.log(data)

  return (
    <div className="summary-container">
      <h2>Resumo da simulação</h2>
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Dessert (100g serving)</TableCell>
          <TableCell align="right">Calories</TableCell>
          <TableCell align="right">Fat&nbsp;(g)</TableCell>
          <TableCell align="right">Carbs&nbsp;(g)</TableCell>
          <TableCell align="right">Protein&nbsp;(g)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.calories}</TableCell>
            <TableCell align="right">{row.fat}</TableCell>
            <TableCell align="right">{row.carbs}</TableCell>
            <TableCell align="right">{row.protein}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  </div>
);
}

export default SummaryScreen;
