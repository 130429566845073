import React, { useState, useEffect } from "react";
import AxiosInstance from "../core/axiosInstance";
import "./SimulationScreen.css";
import { Autocomplete, MenuItem, TextField, Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import utilFormatter from '../utils/formatter.utils'

const steps = ['Dados Gerais', 'Dependentes', 'Planos'];

const SimulationScreen = () => {
  const [origem, setOrigem] = useState("");
  const [cidades, setCidades] = useState([]);
  const [cidadeSelecionada, setCidadeSelecionada] = useState("");
  const [planoSelecionado, setPlanoSelecionado] = useState(false);
  const [planoSelecionadoNome, setPlanoSelecionadoNome] = useState(false);
  const [seguroSelecionado, setSeguroSelecionado] = useState(false);
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [temPlano, setTemPlano] = useState(false);
  const [temPlanoSelecionado, setTemPlanoSelecionado] = useState("");
  const [idade, setIdade] = useState("");
  const [sexo, setSexo] = useState("");
  const [desejaDependente, setDesejaDependente] = useState(false);
  const [dependentes, setDependentes] = useState([{ idade: "", seguro: false, desejaClubeBeneficios: false }]);
  const [desejaClubeBeneficios, setDesejaClubeBeneficios] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [responseTitular, setResponseTitular] = useState(undefined);
  const [responseDependentes, setResponseDependentes] = useState(undefined);
  const [linhaSelecionada, setLinhaSelecionada] = useState(false);
  const [novosDependentes, setNovoDependentes] = useState(false);
  const [idFormulario, setIdFormulario] = useState(false);
  const [idFormularioSoap, setIdFormularioSoap] = useState(false);
  const [valorTotal, setValorTotal] = useState(false);

  const history = useHistory();

  const formatarTelefone = (telefone) => {
    const numerosTelefone = telefone.replace(/\D/g, '');
    return numerosTelefone.replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3');
  };

  // useEffect(() => {
  //   if (activeStep === 2){
  //     const Object = {
  //       origem,
  //       nome,
  //       celular: telefone,
  //       cidade: cidadeSelecionada,
  //       temPlano: temPlanoSelecionado,
  //       plano: planoSelecionado,
  //       idade: parseInt(idade),
  //       sexo,
  //       dependentes: dependentes.map((dependente) => ({
  //         idade: parseInt(dependente.idade),
  //         seguro: parseInt(dependente.seguro),
  //         desejaClubeBeneficios: dependente.desejaClubeBeneficios,
  //       })),
  //       seguro: parseInt(seguroSelecionado),
  //       desejaClubeBeneficios,
  //     };

  //     const response = AxiosInstance.post(
  //       "http://127.0.0.1:5000/form/save",
  //       Object,
  //       {
  //         headers: {
  //           Authorization: `Basic ${localStorage.getItem("accessToken")}`,
  //         },
  //       }
  //     );

  //     console.log("Dados salvos com sucesso:", response.data);
  //     setResponsePlans(response.data);
  //     /*history.push("/summary", { Object, dependentes });*/

  //   }
  // },[activeStep]);

  useEffect(() => {
    const fetchCidades = async () => {
      try {
        const response = await AxiosInstance.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados/33/municipios"
        );
        if (response.status != 200) {
          throw new Error("Erro ao buscar as cidades");
        }

        const data = await response.data;
        const cidadesDoRioDeJaneiro = data.map((cidade) => cidade.nome);
        setCidades(cidadesDoRioDeJaneiro);
      } catch (error) {
        console.error("Erro ao buscar as cidades:", error);
      }
    };

    fetchCidades();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if(activeStep === 1){
      handleSubmit()
    };
    if(activeStep === 2){
      handleUpdateForm()
    }
  };


  const calculateTotalValue = () => {
    var totalValue = 0;
    setValorTotal(totalValue)
    console.log(linhaSelecionada)
    responseTitular.map((titular) =>{
      if(titular.INDEX === linhaSelecionada){
        totalValue += titular.VALORFINAL
        console.log(totalValue + "TITULAR")
      }
    })

    novosDependentes.map((dependente) =>{
      totalValue += dependente.VALORFINAL
      console.log(totalValue + "DEPENDENTE")
    })
    setValorTotal(totalValue)  }

  const handleUpdateForm = async () =>{
    try {
      const totalValue = calculateTotalValue();
      console.log(totalValue)
      const Object = {
        insertIdSoap: idFormularioSoap[0],
        celular: idFormularioSoap[1],
        plano: planoSelecionadoNome,
        valor: totalValue,
        insertId: idFormulario
      };

      console.log(Object)
      const response = await AxiosInstance.post(
        "https://backsimulador.santacasacopacabana.com.br/form/put",
        Object,
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log(response.data);

      

    } catch (error) {
      console.error("Erro ao salvar dados:", error);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setOrigem("");
    setCidadeSelecionada("");
    setPlanoSelecionado("");
    setSeguroSelecionado("");
    setNome("");
    setTelefone("");
    setTemPlano(false);
    setTemPlanoSelecionado("");
    setIdade("");
    setSexo("");
    setDesejaDependente(false);
    setDependentes([{}]);
    setDesejaClubeBeneficios(false);
  };

  const handleSubmit = async () => {
    try {
      const Object = {
        origem,
        nome,
        celular: telefone,
        cidade: cidadeSelecionada,
        temPlano: temPlanoSelecionado,
        plano: planoSelecionado,
        idade: parseInt(idade),
        sexo,
        dependentes: dependentes.map((dependente) => ({
          idade: parseInt(dependente.idade),
          seguro: parseInt(dependente.seguro),
          desejaClubeBeneficios: dependente.desejaClubeBeneficios,
        })),
        seguro: parseInt(seguroSelecionado),
        desejaClubeBeneficios,
      };

      console.log(Object)
      const response = await AxiosInstance.post(
        "https://backsimulador.santacasacopacabana.com.br/form/save",
        Object,
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      console.log("Dados salvos com sucesso:", response.data);
      const planos = response.data;
      console.log(planos.titular);
      console.log(planos.dependentes);
      setResponseTitular(planos.titular)
      setResponseDependentes(planos.dependentes)
      setIdFormulario(planos.insertId)
      setIdFormularioSoap((planos.insertIdSoap).split(';'))
      /*history.push("/summary", { Object, dependentes });*/

    } catch (error) {
      console.error("Erro ao salvar dados:", error);
    }
  };

  const handleAddDependente = () => {
    setDependentes([...dependentes, { idade: "", seguro: false, desejaClubeBeneficios: false }]);
  };

  const handleRemoveDependente = (index) => {
    const novosDependentes = [...dependentes];
    novosDependentes.splice(index, 1);
    setDependentes(novosDependentes);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/";
  };

  useEffect(() => {
    console.log(novosDependentes);
    if(responseTitular !== undefined && novosDependentes !== false) {
      calculateTotalValue();
    }
  },[novosDependentes],[responseTitular])

  const renderStepContent = (step) => {

    switch (step) {
      case 0:
        return (
          <>
            <div className="form-group">
              <TextField
                select
                label="Como conheceu a Santa Casa Copacabana?"
                id="typeText"
                type="text"
                value={origem}
                onChange={(e) => setOrigem(e.target.value)}
                className="textfield"
                variant="standard"
                sx={{ width: "350px" }}
              >
                <MenuItem value="TV Record">TV Record</MenuItem>
                <MenuItem value="Indicação">Indicação</MenuItem>
                <MenuItem value="Outdoor">Outdoor</MenuItem>
                <MenuItem value="Facebook">Facebook</MenuItem>
                <MenuItem value="Instagram">Instagram</MenuItem>
                <MenuItem value="Site">Site</MenuItem>
                <MenuItem value="93 FM">93 FM</MenuItem>
                <MenuItem value="PremiaPão">PremiaPão</MenuItem>
              </TextField>
            </div>

            <div className="form-group">
              <TextField
                label="Nome"
                id="typeText"
                type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                className="textfield"
                variant="standard"
              />
            </div>

            <div className="form-group">
              <TextField
                label="Telefone"
                id="typeText"
                type="text"
                value={telefone}
                onChange={(e) => setTelefone(formatarTelefone(e.target.value))}
                className="textfield"
                variant="standard"
              />
            </div>

            <div className="form-group">
              <Autocomplete
                id="cidade"
                options={cidades}
                getOptionLabel={(option) => option}
                value={cidadeSelecionada}
                onChange={(event, newValue) => setCidadeSelecionada(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                    variant="standard"
                    sx={{ width: "250px" }}
                  />
                )}
              />
              Tem outro plano?
              <div className="toggle-button" onClick={() => setTemPlano(!temPlano)}>
                <div className={`slider ${temPlano ? "checked" : ""}`}></div>
              </div>
            </div>

            {temPlano && (
              <div className="form-group">
                <TextField
                  label="Qual plano?"
                  id="typeText"
                  type="text"
                  value={temPlanoSelecionado}
                  onChange={(e) => setTemPlanoSelecionado(e.target.value)}
                  className="textfield"
                  variant="standard"
                />
              </div>
            )}

            <div className="form-group">
              <TextField
                label="Idade:"
                id="typeText"
                type="text"
                value={idade}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numbersOnly = inputValue.replace(/[^0-9]/g, "");
                  setIdade(numbersOnly);
                }}
                className="textfield"
                variant="standard"
                sx={{ width: "50px" }}
              />
            </div>

            <div className="form-group">
              <TextField
                select
                label="Sexo"
                id="typeText"
                type="text"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
                className="textfield"
                variant="standard"
                sx={{ width: "100px" }}
              >
                <MenuItem value="masculino">Masculino</MenuItem>
                <MenuItem value="feminino">Feminino</MenuItem>
              </TextField>
            </div>

            <div className="form-group">
              <TextField
                defaultValue={"false"}
                placeholder="Não"
                select
                label="Seguro:"
                id="typeText"
                disabled={idade > 79}
                type="text"
                value={seguroSelecionado || "false"}
                onChange={(e) => {
                setSeguroSelecionado(e.target.value) 
                if(e.target.value !== false){
                  setDesejaClubeBeneficios(false)
                }
                }}
                className="textfield"
                variant="standard"
                sx={{ width: "100px" }}
              >
                <MenuItem value={false}>Não</MenuItem>
                <MenuItem value="14">Padrão</MenuItem>
                <MenuItem value="20">Especial</MenuItem>
              </TextField>
              <div className={`${seguroSelecionado !== false ? 'display-none' : 'display-block'} form-group toggle-label`}>
                Deseja clube de benefícios?
                <div 
                className={`${seguroSelecionado !== false ? 'display-none' : 'display-block'} toggle-button`} onClick={() => setDesejaClubeBeneficios(!desejaClubeBeneficios)}>
                  <div className={`slider ${desejaClubeBeneficios ? "checked" : ""}`}></div>
                </div>
                </div>
            </div>
           
          </>
        );
      case 1:
        return (
          <>
            {dependentes.map((dependente, index) => (
              <div key={index} className="form-group">
                <TextField
                  label={`Idade do dependente ${index + 1}`}
                  id="typeText"
                  type="text"
                  value={dependente.idade}
                  onChange={(e) => {
                    const novosDependentes = [...dependentes];
                    novosDependentes[index].idade = e.target.value;
                    setDependentes(novosDependentes);
                  }}
                  className="textfield"
                  variant="standard"
                  sx={{ width: "33%" }}
                />
                <TextField
                  select
                  label={`Seguro do dependente ${index + 1}`}
                  id="typeText"
                  type="text"
                  value={dependente.seguro || false}
                  onChange={(e) => {
                    const novosDependentes = [...dependentes];
                    novosDependentes[index].seguro = e.target.value;
                    if(e.target.value !== false){
                      novosDependentes[index].desejaClubeBeneficios = false;
                    }
                    setDependentes(novosDependentes);
                  }}
                  className="textfield"
                  variant="standard"
                  sx={{ width: "24%" }}
                  
                >
                  <MenuItem value={false}>Não</MenuItem>
                  <MenuItem value="14">Padrão</MenuItem>
                  <MenuItem value="20">Especial</MenuItem>
                </TextField>
                <TextField
                  select
                  label={`Clube de beneficios ${index + 1}`}
                  id="typeText"
                  type="text"
                  value={dependente.desejaClubeBeneficios || "false"}
                  onChange={(e) => {
                    const novosDependentes = [...dependentes];
                    novosDependentes[index].desejaClubeBeneficios = e.target.value;
                    setDependentes(novosDependentes);
                  }}
                  className={`${dependente.seguro !== false ? 'display-none' : 'display-block'} textfield`}
                  variant="standard"
                  sx={{ width: "24%" }}
                >
                  <MenuItem value={false}>Não</MenuItem>
                  <MenuItem value={true}>Sim</MenuItem>
                </TextField>
                <Button onClick={() => handleRemoveDependente(index)}>Remover</Button>
              </div>
            ))}
            <Button onClick={handleAddDependente}>Adicionar dependente</Button>
          </>
        );
      case 2:
        if(responseTitular === undefined){
          console.log("NÃO CARREGOU")
        }
        return (
          <>
            <h2>Titular</h2>
             <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Planos</TableCell>
                    <TableCell>Valor do Plano</TableCell>
                    <TableCell>Valor do Seguro</TableCell>
                    <TableCell>Valor do Clube de Beneficios</TableCell>
                    <TableCell>Valor total Titular</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                {responseTitular !== undefined ? responseTitular.map((row, index) => (
                    <TableRow
                      onClick={() =>  {
                        handleRowClick(row, index)
                        setLinhaSelecionada(index)
                      }}
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      component="th" scope="row"
                      className={linhaSelecionada === index ? 'active' : ''}
                    > 
                      <TableCell  >{row.NOMEFANTASIA}</TableCell>
                      <TableCell   >{utilFormatter(row.VALOR)}</TableCell>
                      <TableCell   >{utilFormatter(row.SEGURO)}</TableCell>
                      <TableCell   >{utilFormatter(row.CLUBE)}</TableCell>
                      <TableCell   >{utilFormatter(row.VALORFINAL)}</TableCell>
                    </TableRow>
                  )) : "NÃO CARREGOU"}
                </TableBody>
              </Table>
            </TableContainer>

            {novosDependentes !== false ?
            <><h2>Dependente</h2><TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Planos</TableCell>
                      <TableCell>Valor do Plano</TableCell>
                      <TableCell>Valor do Seguro</TableCell>
                      <TableCell>Valor do Clube de Beneficios</TableCell>
                      <TableCell>Valor total Dependente</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {novosDependentes !== false ? novosDependentes.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      component="th" scope="row"
                    > 
                      <TableCell  >{row.NOMEFANTASIA}</TableCell>
                      <TableCell   >{utilFormatter(row.VALOR)}</TableCell>
                      <TableCell   >{utilFormatter(row.SEGURO)}</TableCell>
                      <TableCell   >{utilFormatter(row.CLUBE)}</TableCell>
                      <TableCell   >{utilFormatter(row.VALORFINAL)}</TableCell>
                    </TableRow>
                  )) : "NÃO CARREGOU"}

                  </TableBody>
                </Table>
              </TableContainer></> :  ""
              
            } 
            <h2>Valor Total da Simulação</h2>
            <h3 className="custom-typography">
              {utilFormatter(valorTotal)}
            </h3>
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  const handleRowClick = (row, index) => {
    row.INDEX = index;
    const novosDependentes = [];
    // eslint-disable-next-line array-callback-return
    responseDependentes.map((dependente, idx) => {
      dependente.INDEX = idx;
      if(dependente.NOMEFANTASIA === row.NOMEFANTASIA){
        novosDependentes.push(dependente);
      }
    })
    setNovoDependentes(novosDependentes)
    setPlanoSelecionado(row.INDEX);
    setPlanoSelecionadoNome(row.NOMEFANTASIA)
  };

  return (
    <Box 
    sx={{ width: '100%' , height: '100vh'}}
    display="flex"
    alignItems="center"
    justifyContent="center"
    >
      <div className="custom-box">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
  
            if (skipped.has(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              Simulação realizada. Todos os passos foram concluídos!
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Nova simulação</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Passo {activeStep + 1}</Typography>
            {renderStepContent(activeStep)}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Voltar
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};

export default SimulationScreen;
