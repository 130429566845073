// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
  }

  h2 {
    margin-bottom: 40px;
  }
  
  .summary-details {
    margin-bottom: 20px;
  }

  .summary-details p {
    margin-bottom: 25px;
  }
  
  .subtotal {
    margin-bottom: 20px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #88d4f2;
  }
  
  a {
    text-decoration: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SummaryScreen.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".summary-container {\n    max-width: 700px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n\n  h2 {\n    margin-bottom: 40px;\n  }\n  \n  .summary-details {\n    margin-bottom: 20px;\n  }\n\n  .summary-details p {\n    margin-bottom: 25px;\n  }\n  \n  .subtotal {\n    margin-bottom: 20px;\n  }\n  \n  .buttons-container {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  button {\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background-color: #88d4f2;\n  }\n  \n  a {\n    text-decoration: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
